import React from 'react';
import Siema from 'siema';
import Description from './Description';
import '../styles/carousel.css';
import Gallery from './Gallery';
import withLocation from './withLocation';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

const Images = graphql`
    query {
        image1: file(relativePath: { eq: "mega1.png" }) {
            childImageSharp {
                fluid(maxWidth: 650) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        image2: file(relativePath: { eq: "mega2.png" }) {
            childImageSharp {
                fluid(maxWidth: 650) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        image3: file(relativePath: { eq: "mega3.png" }) {
            childImageSharp {
                fluid(maxWidth: 650) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        image4: file(relativePath: { eq: "mega4.png" }) {
            childImageSharp {
                fluid(maxWidth: 650) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        image5: file(relativePath: { eq: "mega5.png" }) {
            childImageSharp {
                fluid(maxWidth: 650) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        image6: file(relativePath: { eq: "mega6.png" }) {
            childImageSharp {
                fluid(maxWidth: 650) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        image7: file(relativePath: { eq: "mega7.png" }) {
            childImageSharp {
                fluid(maxWidth: 650) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        image8: file(relativePath: { eq: "mega8.png" }) {
            childImageSharp {
                fluid(maxWidth: 650) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        image9: file(relativePath: { eq: "mega9.png" }) {
            childImageSharp {
                fluid(maxWidth: 650) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
`;
class Carousel extends React.Component {
    state = {
        desc:
            'Join our community of Pro Gamers who are shortlisted through a 3 step verification process. Experience vast collection of fun and addictive games all in one app. Also win real cash by showing your skills and competing against other players. What are you waiting for?',
        showGallery: false,
        slideToScroll: 1
    };

    componentDidMount() {
        new Siema({
            perPage: 3
        });
    }

    showGallery = (e, index) => {
        e.preventDefault();
        e.stopPropagation();
        window.gtag('event', 'Carousel', {
            event_category: 'Opened Fullscreen image',
            event_label: index,
            value: 1
        });
        this.setState({ showGallery: true, slideToScroll: index });
    };

    hideGallery = () => {
        this.setState({ showGallery: false });
    };

    render() {
        let description = this.props.search.desc || this.state.desc;
        let { desc, showGallery, slideToScroll } = this.state;
        const { installUrl } = this.props;

        return (
            <StaticQuery
                query={Images}
                render={data => {
                    console.log(data);
                    let fluidImgFromQuery = [];
                    for (let key in data) {
                        fluidImgFromQuery.push(data[key].childImageSharp.fluid);
                    }
                    return (
                        <>
                            <div className="container padTop1">
                                <div className="siema">
                                    {fluidImgFromQuery.map((fluid, index) => {
                                        return (
                                            <div
                                                className="padR4"
                                                key={index}
                                                onClick={e =>
                                                    this.showGallery(e, index)
                                                }
                                            >
                                                <Img fluid={fluid} alt="" />{' '}
                                            </div>
                                        );
                                    })}
                                </div>
                                <div className=" is-size-7 descPlain">
                                    <p>
                                        Install GetMega to Play and Win in your
                                        favourite games, to get REAL CASH, in
                                        just 5 minutes?
                                    </p>
                                    <p>
                                        - Non stop addictive games like
                                        tic-tac-toe
                                    </p>
                                    <p>- Only Verified Players, REAL TIME! </p>
                                    <p style={{ marginBottom: '0' }}>
                                        - Non stop earnings, Non stop
                                        entertainment.
                                    </p>

                                    <br />
                                </div>
                                <Description />
                                {/*<b-modal :active.sync="isComponentModalActive" has-modal-card>
              <Gallery :imageIndex="imageIndex" :images="images"></Gallery>
            </b-modal> */}
                            </div>
                            {showGallery ? (
                                <Gallery
                                    slideToScroll={slideToScroll}
                                    hideGallery={this.hideGallery}
                                    fbEventAndShowModal={
                                        this.props.fbEventAndShowModal
                                    }
                                    installUrl={installUrl}
                                />
                            ) : null}
                        </>
                    );
                }}
            />
        );
    }
}

export default withLocation(Carousel);
