import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

const Images = graphql`
    query {
        image1: file(relativePath: { eq: "mega1.png" }) {
            childImageSharp {
                fluid(maxWidth: 650) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        image2: file(relativePath: { eq: "mega2.png" }) {
            childImageSharp {
                fluid(maxWidth: 650) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        image3: file(relativePath: { eq: "mega3.png" }) {
            childImageSharp {
                fluid(maxWidth: 650) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        image4: file(relativePath: { eq: "mega4.png" }) {
            childImageSharp {
                fluid(maxWidth: 650) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        image5: file(relativePath: { eq: "mega5.png" }) {
            childImageSharp {
                fluid(maxWidth: 650) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        image6: file(relativePath: { eq: "mega6.png" }) {
            childImageSharp {
                fluid(maxWidth: 650) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        image7: file(relativePath: { eq: "mega7.png" }) {
            childImageSharp {
                fluid(maxWidth: 650) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        image8: file(relativePath: { eq: "mega8.png" }) {
            childImageSharp {
                fluid(maxWidth: 650) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        image9: file(relativePath: { eq: "mega9.png" }) {
            childImageSharp {
                fluid(maxWidth: 650) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
`;

export default class Gallery extends React.Component {
    componentDidMount() {
        this.slider.slickGoTo(this.props.slideToScroll);
    }

    render() {
        let { slideToScroll, hideGallery, installUrl } = this.props;

        const settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 1
            //   slidesToScroll: `${slideToScroll}`
            //slidesToScroll:slideToScroll
        };

        return (
            <StaticQuery
                query={Images}
                render={data => {
                    console.log(data);
                    let fluidImgFromQuery = [];
                    for (let key in data) {
                        fluidImgFromQuery.push(data[key].childImageSharp.fluid);
                    }
                    return (
                        <>
                            <div className="custom-modal gallery-container">
                                <div
                                    className="gallery-close"
                                    onClick={hideGallery}
                                >
                                    {' '}
                                    <span className=" is-large">
                                        <strong
                                            style={{ color: '#fff' }}
                                            className="mdi "
                                        >
                                            X
                                        </strong>
                                    </span>
                                </div>
                                <div className="gallery-content">
                                    <div className="gallery-slider">
                                        <Slider
                                            ref={slider =>
                                                (this.slider = slider)
                                            }
                                            {...settings}
                                        >
                                            {fluidImgFromQuery.map(
                                                (fluid, index) => {
                                                    return (
                                                        <div key={index}>
                                                            <Img
                                                                fluid={fluid}
                                                                alt=""
                                                            />
                                                        </div>
                                                    );
                                                }
                                            )}
                                        </Slider>
                                    </div>
                                    <div className="column has-text-centered">
                                        <a href={installUrl}>
                                            <button
                                                size="is-small"
                                                className="button-install has-text-weight-medium"
                                                onClick={() =>
                                                    this.props.fbEventAndShowModal(
                                                        'Gallery Button'
                                                    )
                                                }
                                            >
                                                INSTALL
                                            </button>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </>
                    );
                }}
            />
        );
    }
}
