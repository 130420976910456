import React from 'react';
import withLocation from './withLocation';
import Logo from '../images/logo.png';
import Stats from './Stats';

class Header extends React.Component {
    state = {
        isOpen: false,
        staticTags: ['Genuine Profile', 'Top Games', 'Win Cash']
    };

    onClickHandler = eventSource => {
        this.props.fbEventAndShowModal(eventSource);
    };

    render() {
        let { tag1, tag2, tag3 } = this.props.search;
        let { installUrl } = this.props;
        console.log('install url is :', installUrl);
        let tags = [];
        tag1 && tags.push(tag1);
        tag2 && tags.push(tag2);
        tag3 && tags.push(tag3);

        let tagsArr = tags.length !== 0 ? tags : this.state.staticTags;
        return (
            <>
                <div className="header container">
                    <div className="imageSection">
                        <div className="logo">
                            <a
                                onClick={() => {
                                    this.onClickHandler('Logo Button');
                                }}
                                href={installUrl}
                                style={{
                                    maxWidth: 70
                                }}
                            >
                                <img src={Logo} alt="" />
                            </a>
                        </div>
                        <div className="app-name">
                            <a
                                onClick={() => {
                                    this.onClickHandler('Title Button');
                                }}
                                href={installUrl}
                            >
                                <p className=" weight-500 size-med ">GetMega</p>
                            </a>
                            <p
                                className="is-size-7 weight-500 developer"
                                style={{ cursor: 'pointer' }}
                            >
                                MegaGames
                            </p>
                            <div className="developer">
                                {tagsArr.map(tag => (
                                    <span key={tag} className="tag is-rounded">
                                        {tag}
                                    </span>
                                ))}
                                {/* <span className='tag is-rounded'>Fun Game</span>
                <span className='tag is-rounded'>Win cash</span>
                <span className='tag is-rounded'>Top Free</span> */}
                            </div>
                            {/* <div className="editors-choice">
                <img src="https://lh3.googleusercontent.com/2RVYfvNtygyNIBk2wc738qDUt9O3DCUw51_gXtBQiLOK2IYV2XRZEUy_C1_44hGMYrpkJ14SgufYUT4N=s14-rw" alt="EC" />
                <span>Editors' Choice</span>
              </div> */}
                            {/* <div className="last-updated">
                <span>Last Updated:{" "}{this.getDate()}</span>
              </div> */}
                        </div>
                    </div>
                    <div className="stats-container">
                        <Stats
                            fbEventAndShowModal={this.props.fbEventAndShowModal}
                            installUrl={installUrl}
                        />
                    </div>
                    <div
                        className="install-button"
                        id="install-button-container"
                    >
                        <a
                            onClick={() => {
                                this.onClickHandler('Primary Install Button');
                            }}
                            href={installUrl}
                            id="install-ref"
                        >
                            <button
                                size="is-small"
                                className="weight-500"
                                id="install-button-main"
                            >
                                INSTALL
                            </button>
                        </a>
                    </div>
                </div>
            </>
        );
    }
}

export default withLocation(Header);
