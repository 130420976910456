import React from 'react';
import '../styles/stats.css';
import star from '../images/svg/star.svg';
import downloadIcon from '../images/download_icon.png';

class Stats extends React.Component {
    onClickHandler = eventSource => {
        this.props.fbEventAndShowModal(eventSource);
    };

    render() {
        const { installUrl } = this.props;

        return (
            //  <div className="container">
            <>
                <div className="stats-reviews">
                    <a href="#reviews">
                        <div className="ratingDiv">
                            <p className="topText has-text-grey-darker">
                                <span
                                    style={{ marginRight: '-4px' }}
                                    className="weight-500 is-size-7 has-text-grey-darker"
                                >
                                    4.7
                                </span>
                                <span className="icon has-text-grey-darker">
                                    {/* <i className="mdi mdi-star mdi-24px"></i> */}
                                    <img className="ratingStar" src={star} />
                                </span>
                            </p>
                        </div>
                        <p
                            style={{ marginTop: '-4px' }}
                            className="is-size-7 has-text-grey has-text-centered"
                        >
                            10K Reviews
                        </p>
                    </a>
                </div>

                <div className="stats-install">
                    <a
                        onClick={() => {
                            this.onClickHandler('Icon Button');
                        }}
                        href={installUrl}
                    >
                        <div className="downloadDivParent">
                            <div className="downloadDivChild weight-500">
                                <img
                                    className="downloadIc"
                                    src={downloadIcon}
                                    alt="download"
                                />
                            </div>
                        </div>
                        <p className="is-size-7 has-text-grey has-text-centered">
                            12 MB
                        </p>
                    </a>
                </div>
                <div className="stats-downloads">
                    <p className="weight-500 is-size-7 has-text-grey-darker has-text-centered">
                        130K+
                    </p>
                    <p className="is-size-7 has-text-grey has-text-centered">
                        Downloads
                    </p>
                </div>
            </>
            // </div>
        );
    }
}

export default Stats;
