import React from 'react';
import '../styles/reviewCard.css';
import starReview from '../images/star_green.png';
import {getPastDate} from './utils/pastdate';
import DynamicRating from './DynamicStar';
export default class ReviewCard extends React.Component {
    state = {
        colors: ["pink", "green", "blue", "grey", "teal"]
    }
    starCheck(stars) {
         return `rev${parseInt(stars)}`
      }
      initial(review) {
        return review.name.split("")[0].toUpperCase();
      }
      initialsColor() {
        return this.state.colors[Math.floor(Math.random() * this.state.colors.length)];
      }
      
    render() {
        let { className, review } = this.props;
        // console.log(review);
        let elements=[];
        // for(let i=parseInt(review.stars);i>0;i--){
        //     elements.push(
        //         <span className="icon is-small" key={i}>
        //             {/* <i className="mdi mdi-star"></i> */}
        //             <img src={starReview} alt="" />
        //         </span>
        //     )
        // }
        let value = (parseInt(review.stars)/5)*100;
    
        return (

            <div className={`reviewCard ${className}`}  id={this.starCheck(review.stars)}>
                <div className="profSection">
                    <p className={`initials is-size-7 ${this.initialsColor()}`} >{this.initial(review)}</p>
                <p className="reviewer-name">{review.name}</p>
            </div>
            
            <div className="ratings">
                <div className="stars">
                  {/* {elements} */}
                  <DynamicRating value={value} />
              </div>
            <p className="is-size-7 has-text-grey marTop3">{getPastDate(review.date)}</p>
            </div >
            <div className="review">
                <p className="has-text-grey is-size-7 margBot7">{review.msg}</p>
               {review.reply?(<div className="reply is-size-7 has-text-grey">
                    <span className="arrowIcon is-medium" ><i className="mdi mdi-menu-up"></i></span>
                    <p className="margBot7 flex-reply"> <span className="has-text-black ">GetMega</span><span>{ getPastDate(review.reply_date)}</span></p>
                    <p>{review.reply}</p>
                </div>):null}
            </div>
          </div >
           
        )
    }
}