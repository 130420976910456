var Airtable = require("airtable");
const fetchRecords = async () => {

    let reviews = [];
    let reviewsByRating = {};
    var base = new Airtable({ apiKey: "keyg7QpMQjMIOiZiq" }).base(
        "appR3qBybARpMvzOA"
    );

    let result = await new Promise((resolve, reject) => {
        base("Reviews")
            .select({

                sort: [{ field: "order" }]
                // Selecting the first 3 records in Grid view:
            })
            .eachPage(
                function page(records, fetchNextPage) {
                    // This function (`page`) will get called for each page of records.
                    //    console.log("respose :",records)
                    records.forEach(function (element) {
                        if (reviewsByRating[element.fields.stars] === undefined) {
                            reviewsByRating[element.fields.stars] = [];
                            reviewsByRating[element.fields.stars].push(element.fields);

                        }
                        else {
                            reviewsByRating[element.fields.stars].push(element.fields);
                        }
                        reviews.push(element.fields);
                    });

                    // To fetch the next page of records, call `fetchNextPage`.
                    // If there are more records, `page` will get called again.
                    // If there are no more records, `done` will get called.
                    fetchNextPage();
                },
                function done(err) {

                    if (err) {
                        console.error(err);
                        return;
                    }
                    console.log("returning data", reviews, reviewsByRating)
                    resolve({ reviews, reviewsByRating });
                }
            )
    });
    console.log("after await", result);
    return result;
}



const data = [{"id":"recNtZ87BfP3tLuFE","fields":{"name":"Jaipal Nain","msg":"This game is very osm. I earned a lot of money while playing all the games. My request to the developers is that please bring the new updates. I am very excited for the new update. I am very happy to play the game.","stars":5,"date":"10","order":"1"},"createdTime":"2019-08-15T16:46:11.000Z"},{"id":"rec7SVo1CKJXa26bE","fields":{"name":"Krishna Dhole","msg":"one of the best games and please install this wonderful app its best to timepass in college and lots of interesting games and special thanks to game editor who edit this game. well done...","stars":5,"date":"4","order":"2"},"createdTime":"2019-08-15T16:46:11.000Z"},{"id":"recr3FUD8ml5WQPJl","fields":{"name":"shashank singh","msg":"Wow! I'm in love with this game and I suggest you add more games and maine kafi sara paisa jeeta ek din mein.","stars":5,"date":"1","order":"3"},"createdTime":"2019-08-15T16:46:11.000Z"},{"id":"recJN9LrrAdRfZMGJ","fields":{"name":"Ravindra Thapar","msg":"You make a good game, maine bhot saara money win kiya. I WANT MORE GAMES!!","stars":5,"date":"3","order":"4"},"createdTime":"2019-08-15T16:46:31.000Z"},{"id":"recMfJ2eXt5iimGpy","fields":{"name":"Goutam Suryavanshi","msg":"I loved it man!! It's just awesome. All of my friends play this together in canteen, its a good timepass between lecture. Thats all & i hope that the developer will respond to my review and update it by 19 the latest. 👍","stars":5,"date":"9","order":"5"},"createdTime":"2019-08-15T16:46:31.000Z"}]

const staticRecords = data.map(obj => obj.fields);
export { fetchRecords, staticRecords };