import React from "react";
import Header from "./Header";
import "buefy/dist/buefy.css";
import "../styles/header.css";
import Carousel from "./Carousel";
import ReviewsList from "./ReviewsList";
import { generateUrl } from "./utils/generateUrl";
import { defaultInstallUrl, defaultCasualUrl } from "./utils/installUrl";
import FreeGemsPopup from "./FreeGemsPopup";

class App extends React.Component {
    state = {
        reviews: [],
        reviewsByRating: {},
        isOpen: false,
        scrollHeight: 0,
        initialMount: true,
        progress: 0,
        // installUrl: defaultInstallUrl
        installUrl: defaultCasualUrl
    };

    toggleModal = () => {
        this.setState(state => ({ isOpen: !state.isOpen }));
    };

    tiktokPixelConversion = () => {
        console.log("running tik tok pixel conversion code");
        //old pixel
        var ta1 = document.createElement("script");
        ta1.type = "text/javascript";
        ta1.async = true;
        ta1.src =
            document.location.protocol +
            "//" +
            "static.bytedance.com/pixel/sdk.js?sdkid=BMNSLLKB5B70QD216IF0";
        var s1 = document.getElementsByTagName("head")[0];
        s1.appendChild(ta1);

        //new pixel
        var ta = document.createElement("script");
        ta.type = "text/javascript";
        ta.async = true;
        ta.src =
            document.location.protocol +
            "//" +
            "static.bytedance.com/pixel/sdk.js?sdkid=BN93K3C0M0F0PKIEFCO0";
        var s = document.getElementsByTagName("head")[0];
        s.appendChild(ta);
    };

    gtag_report_conversion = url => {
        var callback = function() {
            if (typeof url != "undefined") {
                window.location = url;
            }
        };
        window.gtag("event", "conversion", {
            send_to: "AW-718511341/bObTCLPJlKgBEO25ztYC",
            event_callback: callback
        });
        return false;
    };

    fbEventAndShowModal = eventSource => {
        console.log("fbevent:", eventSource);

        window.fbq("trackCustom", "Login");
        window.gtag("event", "Download APK", {
            event_category: "Downloaded",
            event_label: eventSource,
            value: 1
        });
        //  window._loq.push(["tag", "Download Click", true]);
        this.setState(state => ({ isOpen: !state.isOpen }));

        this.tiktokPixelConversion();
        this.gtag_report_conversion();
    };

    async componentDidMount() {
        // generate onelink url
        let url = generateUrl();
        if (url && url !== " ") this.setState({ installUrl: url });

        let self = this;

        window.onscroll = function() {
            console.log("reviews scroll", window.pageYOffset);
            if (self.state.initialMount && window.pageYOffset > 5) {
                self.setState(
                    { scrollHeight: window.pageYOffset, initialMount: false },
                    () => {
                        console.log("state is set");
                    }
                );
            }
        };

        window.addEventListener("scroll", () => {
            console.log("executing scroll listener");
            if (window.pageYOffset > 200 && this.state.progress !== 200) {
                console.log("setting progress");
                this.setState({ progress: 200 });
            }
            if (window.pageYOffset < 200 && this.state.progress !== 0) {
                console.log("unsetting progress");
                this.setState({ progress: 0 });
            }
        });

        // open free gems popup after 30 seconds
        const freeGemsTimer = setTimeout(() => {
            this.setState({ isFreeGemsPopupOpen: true });
        }, 30 * 1000);
        this.setState({ freeGemsTimer });
    }

    componentWillUnmount() {
        window.onscroll = null;
        clearTimeout(this.state.freeGemsTimer);
    }

    filterReviews = filter => {
        console.log("calling filter");
        let { reviewsByRating, reviews } = this.state;
        let filteredReviews = [];
        let remainingReviews = [];
        console.log("req reviews:", reviewsByRating[filter]);

        filteredReviews =
            (reviewsByRating[filter.toString()] && [
                ...reviewsByRating[filter.toString()]
            ]) ||
            [];
        console.log("filtered reviews:", filteredReviews);
        remainingReviews = reviews.filter(
            review => review.stars !== parseInt(filter)
        );
        filteredReviews = [...filteredReviews, ...remainingReviews];
        this.setState({ reviews: filteredReviews });
    };

    closeFreeGemsPopup = () => {
        this.setState({ isFreeGemsPopupOpen: false });
    };

    render() {
        let {
            reviews,
            scrollHeight,
            progress,
            installUrl,
            isFreeGemsPopupOpen
        } = this.state;
        console.log("progess :", progress);
        let transitionClass = progress >= 200 ? "fade-in" : "fade-out";
        console.log(transitionClass);
        return (
            <>
                {this.state.isOpen ? (
                    <div className="custom-modal">
                        <iframe
                            src="https://player.vimeo.com/video/342926432?title=0&byline=0&portrait=0&autoplay=1&loop=1&background=1"
                            width="640"
                            height="1138"
                            frameBorder="0"
                            allowFullScreen={true}
                        ></iframe>
                        <span className="is-large" onClick={this.toggleModal}>
                            <strong style={{ color: "#fff" }} className="mdi ">
                                X
                            </strong>
                        </span>
                    </div>
                ) : null}
                <div
                    id="app"
                    className={`${this.state.isOpen ? "no-scrolling" : ""}`}
                >
                    <Header
                        className="container"
                        fbEventAndShowModal={this.fbEventAndShowModal}
                        installUrl={installUrl}
                    />
                    <hr />

                    <Carousel
                        className="container "
                        fbEventAndShowModal={this.fbEventAndShowModal}
                        installUrl={installUrl}
                    />
                    <hr />
                    <ReviewsList scrollHeight={scrollHeight} />
                </div>
                <div
                    className={`install-button-bottom sticky-install-button ${transitionClass}`}
                >
                    <a
                        onClick={() => {
                            this.fbEventAndShowModal("Sticky Install Button");
                        }}
                        href={installUrl}
                    >
                        <button size="is-small" className="weight-500">
                            INSTALL
                        </button>
                    </a>
                </div>
                {isFreeGemsPopupOpen && (
                    <FreeGemsPopup
                        closeFreeGemsPopup={this.closeFreeGemsPopup}
                        installUrl={installUrl}
                        fbEventAndShowModal={this.fbEventAndShowModal}
                    />
                )}
            </>
        );
    }
}
export default App;
