import React from "react"
import Layout from "../components_images/layout"
import SEO from "../components_images/seo"
import App from "../components_images/App"


const IndexPage = () => {
 return (
  
  <>
  <Layout>
    <SEO title="GetMega" />
    <App />
  </Layout>
  </>
)
  }

export default IndexPage
