import React from 'react';
import { staticRecords } from '../fetchAirtableRecords';
import ReviewCard from './ReviewCard';

export default class StaticReviews extends React.Component {
    shouldComponentUpdate(){
        return false;
    }
    render() {
        return (
            <>
                {staticRecords.map((review, index) => {

                    return (
                        <div id="reviews" key={index}>
                            <ReviewCard review={review} className="marg16" />
                            <hr />
                        </div>
                    )
                })}
            </>


        )
    }
}