import React from 'react';

class Description extends React.Component {
    state = {
        showDescription: false
    }
    toggleShowDescription = () => {
        this.setState(state => {
            return { showDescription: !state.showDescription }
        })
    }
    render() {
        let { showDescription } = this.state;
        return (
            <>
            <div>
                {!showDescription ? 
                <p onClick ={this.toggleShowDescription} className="is-capitalized playColor has-text-weight-semibold is-size-7 has-text-centered"
                >READ MORE</p> : null}
                {showDescription ? (<div >
                    <div className="content is-size-7">
                        <p>3 steps to earning real CASH, non stop, on GetMega.</p>
                        <p>1. Choose your favourite game, from epic addictive games on GetMega</p>
                        <p>2. Challenge and beat real genuine people / gamers like you. </p>
                        <p>3. Take home REAL cash as winnings. Directly to Paytm, UPI, Bank account. </p>
                        
                        <p>India's ONLY authentic gaming app where only the best gamers, win Real Cash in minutes! </p>
                        <p>Install Getmega now and compete head on against verified gamers and show them who is the boss</p>
                        
                        <ul>
                            
                            <li>REAL time instant matchmaking</li>
                            <li>LIVE games all the time</li>
                            <li>100% Safe &amp; Secure</li>
                            <li>Smooth Deposits</li>
                            <li>Fastest Withdrawals on Paytm, google pay, UPI, bank account etc</li>
                            <li>Efficient Customer Support</li>
                            <li>Only Verified Profiles</li>
                            <li>Play Addictive games like Tic Tac Toe, Tetris, BubblePoP and more</li>
                        </ul>
                        
                        <p>
                            Start playing EPIC games on GetMega, and keep winning REAL cash! NON STOP!
                        </p>
                    </div>
                  
                    <p onClick ={this.toggleShowDescription}
                    className="is-capitalized playColor has-text-weight-semibold is-size-7 has-text-centered"
                    >COLLAPSE</p>
                </div>) : null}
            </div >
            <br />
            </>
        )
    }
}

export default Description;