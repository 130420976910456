import React from 'react';
import ReviewCard from './ReviewCard';
import Reviews from './Reviews';
import StaticReviews from './StaticReviews';
import { fetchRecords, staticRecords } from '../fetchAirtableRecords';

class ReviewsList extends React.Component {
    state = {
        reviews: [],
        reviewsByRating: {},
        showReviewsList: false,
        initialMount: true
    }
    async componentWillReceiveProps(newProps) {
        console.log("new props", newProps)
        if (this.state.initialMount && newProps.scrollHeight > 5) {
            console.log("setting new records")
            let { reviews, reviewsByRating } = await fetchRecords();
            this.setState({ reviews, reviewsByRating, showReviewsList: true, initialMount: false })
        }
    }
    async componentDidMount() {

        // let {reviews,reviewsByRating} = await fetchRecords();

        // console.log("fetched records:");
        // this.setState({ reviews, reviewsByRating })

    }
    filterReviews = (filter) => {
        console.log("calling filter")
        let { reviewsByRating, reviews } = this.state;
        let filteredReviews = [];
        let remainingReviews = [];
        console.log("req reviews:", reviewsByRating[filter]);

        filteredReviews = (reviewsByRating[filter.toString()] && [...reviewsByRating[filter.toString()]]) || [];
        console.log("filtered reviews:", filteredReviews);
        remainingReviews = reviews.filter(review => review.stars !== parseInt(filter));
        filteredReviews = [...filteredReviews, ...remainingReviews];
        this.setState({ reviews: filteredReviews });
    }
    render() {
        let { reviews, showReviewsList } = this.state;
        console.log("review inside list", reviews)
        console.log("static records", staticRecords);
        console.log("rendering reviews list")
        let totalReviews = [...staticRecords, ...reviews];
        let reviewElements = reviews.map((review, index) => {
            return (
                <div id="reviews" key={index}>
                    <ReviewCard review={review} className="marg16" />
                    <hr />
                </div>
            )
        })
        return (
            <>
                <Reviews className="container" filterReviews={this.filterReviews} />
                <hr />
                <div className="container reviews-container">

                    <StaticReviews />
                    {showReviewsList ? reviewElements : null}
                    <div className="extra-review" ></div>
                </div>
            </>
        )
    }
}

export default ReviewsList;