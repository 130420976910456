import React from "react";
import { StaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import CloseButton from "../images/close-button.png";

import "../styles/FreeGemsPopup.css";

const Images = graphql`
    query {
        image1: file(
            relativePath: { eq: "free-gems-popup/free-gems-popup.png" }
        ) {
            childImageSharp {
                fluid(maxWidth: 1000) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        image2: file(
            relativePath: { eq: "free-gems-popup/free-gems-popup-install.png" }
        ) {
            childImageSharp {
                fluid(maxWidth: 650) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
`;

class FreeGemsPopup extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            minutes: "01",
            seconds: "30",
            downloadsCount: 2378
        };
    }

    componentDidMount() {
        // start timer for 1.5minutes
        this.startTimer(60 * 1.5);
    }

    componentWillUnmount() {
        clearInterval(this.state.countdownTimer);
    }

    startTimer = duration => {
        let timer = duration,
            minutes,
            seconds;

        const countdownTimer = setInterval(() => {
            minutes = parseInt(timer / 60, 10);
            seconds = parseInt(timer % 60, 10);

            this.setState({
                minutes: minutes < 10 ? "0" + minutes : minutes,
                seconds: seconds < 10 ? "0" + seconds : seconds,
                downloadsCount:
                    this.state.downloadsCount + (seconds % 10 === 0 ? 1 : 0)
            });

            if (--timer < 0) {
                this.props.closeFreeGemsPopup();
            }
        }, 1000);
        this.setState({ countdownTimer });
    };

    render() {
        const { closeFreeGemsPopup, installUrl } = this.props;
        const { minutes, seconds, downloadsCount } = this.state;

        return (
            <div id="free-gems-backdrop">
                <div id="free-gems-popup">
                    <div className="close">
                        <div onClick={closeFreeGemsPopup}>
                            <img
                                src={CloseButton}
                                alt="close button"
                                style={{ width: "100%" }}
                            />
                        </div>
                    </div>
                    <span
                        style={{
                            fontSize: "24px",
                            // fontWeight: "600"
                            marginTop: "10px"
                        }}
                    >
                        Signup now
                    </span>
                    <span
                        style={{
                            fontSize: "24px",
                            fontWeight: "200"
                            // marginTop: "-8px"
                        }}
                    >
                        and win 10 FREE Passes!
                    </span>
                    <span style={{ marginTop: "10px" }}>Offer ends soon.</span>
                    <div className="timer">
                        <div>
                            <span>00</span>
                            <span className="text">HOURS</span>
                        </div>
                        <span>:</span>
                        <div>
                            <span>{minutes}</span>
                            <span className="text">MINUTES</span>
                        </div>
                        <span>:</span>
                        <div>
                            <span>{seconds}</span>
                            <span className="text">SECONDS</span>
                        </div>
                    </div>
                    <StaticQuery
                        query={Images}
                        render={data => {
                            let fluidImgFromQuery = [];
                            for (let key in data) {
                                fluidImgFromQuery.push(
                                    data[key].childImageSharp.fluid
                                );
                            }

                            return (
                                <div
                                    style={{
                                        width: "212px",
                                        marginTop: "-16px"
                                    }}
                                >
                                    <div>
                                        <Img
                                            fluid={fluidImgFromQuery[0]}
                                            alt=""
                                        />
                                    </div>
                                    <div>
                                        <a
                                            onClick={() => {
                                                closeFreeGemsPopup();
                                                this.props.fbEventAndShowModal(
                                                    "Free Gems Popup Install Button"
                                                );
                                            }}
                                            href={installUrl}
                                        >
                                            <Img
                                                fluid={fluidImgFromQuery[1]}
                                                alt=""
                                            />
                                        </a>
                                    </div>
                                </div>
                            );
                        }}
                    />
                    {/* <StaticQuery
                            query={Images}
                            render={data => {
                                return (
                                    <a
                                        onClick={() => {
                                            closeFreeGemsPopup();
                                            this.props.fbEventAndShowModal(
                                                "Free Gems Popup Install Button"
                                            );
                                        }}
                                        href={installUrl}
                                    >
                                        <Img
                                            fluid={
                                                data.image2.childImageSharp
                                                    .fluid
                                            }
                                            alt=""
                                        />
                                    </a>
                                );
                            }}
                        /> */}
                    <span style={{ marginTop: "16px" }}>
                        Today's downloads : {downloadsCount}
                    </span>
                </div>
            </div>
        );
    }
}

export default FreeGemsPopup;
