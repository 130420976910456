import React from 'react';
import '../styles/dynamicStar.css'

export default   ({value}) =>{
    return(
   <div className="ratings-dynamic">
        <div className="empty-stars"></div>
        <div className="full-stars" style={{width:`${value}%`}} ></div>
    </div>
    )
}